import * as _ from 'lodash';
import {
  HTTP_PARAMETER_REMOVE_LIST,
  HTTP_PARAM_ROUTE_ID,
} from '../util/UrlConstants';
import { PREFIX_ORDER_ID, PREFIX_ROUTE_ID } from '../util/FilterConstants';
import FilterHelper from '../util/FilterHelper';
import MessagesController from './MessagesController';
import SearchByIdController from './SearchByIdController';
import SiteController from './SiteController';
import TimeHelper, { ERROR_MESSAGE_TIMEOUT } from 'util/TimeHelper';
import UrlHelper from '../util/UrlHelper';
import siteStore from '../stores/siteStore';

export default class UrlController {
  static async handleRouteIdParameterChange(routeId: string) {
    if (routeId) {
      await UrlController.waitUntilSiteIsLoaded();

      if (UrlController.isAuthorizedForSite()) {
        const routeIdCode = FilterHelper.makeFilterString(
          PREFIX_ROUTE_ID,
          routeId
        );
        SearchByIdController.handleSearchByIdSelection(routeIdCode);
      } else {
        UrlHelper.removeParameter(HTTP_PARAM_ROUTE_ID);
      }
    }
  }

  static async handleOrderIdsParameterChange(orderIdsParameterValue: string) {
    if (orderIdsParameterValue) {
      await UrlController.waitUntilSiteIsLoaded();

      if (UrlController.isAuthorizedForSite()) {
        const orderIds = UrlController.parseOrderIdsFromParameter(
          orderIdsParameterValue
        );
        const orderIdCodes = orderIds.map((orderId) =>
          FilterHelper.makeFilterString(PREFIX_ORDER_ID, orderId)
        );
        SearchByIdController.handleMultipleSearchByIdSelection(orderIdCodes);
        HTTP_PARAMETER_REMOVE_LIST.forEach((httpParameterName) =>
          UrlHelper.removeParameter(httpParameterName)
        );
      }
    }
  }

  static async handleSiteCodeParameterChange(siteCode: string) {
    if (siteCode) {
      // wait until all site data has been loaded before looking for site code match on the site code
      await TimeHelper.waitTillPredicateIsTrue(() => {
        const { sitesLoaded } = siteStore;
        return sitesLoaded;
      }, true);
      const { allSiteNames } = siteStore;
      const siteName = allSiteNames.find(
        (siteName) => siteName.siteCode === siteCode
      );
      if (siteName) {
        const siteCodeSearchString = SiteController.getSearchable(siteName);
        siteStore.setFilteredSiteNamesAndSearchString(siteCodeSearchString, [
          siteName,
        ]);
        await SiteController.handleSiteNameSearchEnterKeyPressed();
      } else {
        MessagesController.addAlertMessage(
          `Site code '${siteCode}' does not exist`,
          ERROR_MESSAGE_TIMEOUT
        );
      }
    }
  }

  static async handleServiceAreaIdParameterChange(serviceAreaId: string) {
    if (serviceAreaId) {
      // wait until all site data has been loaded before looking for site code match on the service area id
      await TimeHelper.waitTillPredicateIsTrue(() => {
        const { sitesLoaded } = siteStore;
        return sitesLoaded;
      }, true);
      const { allSiteNames } = siteStore;
      const siteName = allSiteNames.find(
        (siteName) => siteName.serviceAreaId === serviceAreaId
      );
      if (siteName) {
        const siteCodeSearchString = SiteController.getSearchable(siteName);
        siteStore.setFilteredSiteNamesAndSearchString(siteCodeSearchString, [
          siteName,
        ]);
        await SiteController.handleSiteNameSearchEnterKeyPressed();
      } else {
        MessagesController.addAlertMessage(
          `Service area id '${serviceAreaId}' does not exist`,
          ERROR_MESSAGE_TIMEOUT
        );
      }
    }
  }

  private static async waitUntilSiteIsLoaded() {
    return TimeHelper.waitTillPredicateIsTrue(() => {
      const { loadingSite } = siteStore;
      return !loadingSite;
    });
  }

  private static isAuthorizedForSite() {
    const { authorizedForSelectedSiteName } = siteStore;
    return authorizedForSelectedSiteName;
  }

  /**
   *
   * Support two possible JSON formats which looks like
   *
   * // this format is output for Grok Admin
   * {
   *     "someExceptionName": ["orderId1", "orderId2"]
   * }
   *
   * or
   *
   * // this format is output for Compass
   * {
   *     "someExceptionName": [
   *       {"orderId1": "ACTIVE"},
   *       {"orderId2": "RESOLVED"}
   *     ]
   * }
   *
   * or a comma delimited list of order ids
   *
   * @param orderIdsParameter
   * @private
   */
  private static parseOrderIdsFromParameter(
    orderIdsParameter: string
  ): string[] {
    const orderIds: string[] = [];
    try {
      const escapedJson = orderIdsParameter.replaceAll('\\"', '"');
      const orderIdsObject = JSON.parse(escapedJson);
      const exceptionNames = _.keys(orderIdsObject);
      for (const exceptionName of exceptionNames) {
        const orderIdsForException = orderIdsObject[exceptionName];
        if (Array.isArray(orderIdsForException)) {
          for (const orderIdForException of orderIdsForException) {
            if (typeof orderIdForException === 'string') {
              // array element is a string
              const validOrderId = orderIdForException.trim();
              if (validOrderId !== '') {
                orderIds.push(validOrderId);
              }
            } else if (typeof orderIdForException === 'object') {
              // array element is an object {"orderId1": "ACTIVE"}
              const orderIdsFromObject = _.keys(orderIdForException);
              orderIdsFromObject.forEach((orderIdFromObject) => {
                const validOrderId = orderIdFromObject.trim();
                if (validOrderId !== '') {
                  orderIds.push(validOrderId);
                }
              });
            }
          }
        }
      }
    } catch (e) {
      // failed JSON parsing, so may be comma delimited list of order ids
      const splitOrderIds = orderIdsParameter.split(',');
      for (const splitOrderId of splitOrderIds) {
        if (typeof splitOrderId === 'string') {
          const validOrderId = splitOrderId.trim();
          if (validOrderId !== '') {
            orderIds.push(validOrderId);
          }
        }
      }
    }
    return orderIds.sort();
  }
}
